import { RETAILER_IDS } from "./retailers";

export const API_BASES = [
  {
    Key: "php-dev",
    Label: "Dev",
    Url: "https://marketshareapi-dev.ms.ascentialedge.com"
  },
  {
    Key: "php-stage",
    Label: "Stage",
    Url: "https://marketshareapi-stage.ms.ascentialedge.com"
  },
  {
    Key: "php-prod",
    Label: "Prod",
    Url: "https://marketshareapi.ascentialedge.com"
  },
  {
    Key: "php-qa",
    Label: "QA",
    Url: "https://marketshareapi-qa.ms.ascentialedge.com"
  },
  {
    Key: "node-qa",
    Label: "Node QA",
    Url: "https://marketshareapi-node-qa.ms.ascentialedge.com"
  },
  {
    Key: "node-stage",
    Label: "Node Stage",
    Url: "https://marketshareapi-node-stage.ms.ascentialedge.com"
  },
  {
    Key: "node-prod",
    Label: "Node Prod",
    Url: "https://marketshareapi-node.ascentialedge.com"
  }
];

export const API_BASE_DEFAULT_URL =
  process.env.REACT_APP_MS_API_NEW ||
  // If no env var is set, then return a suitable API based on the browser hostname.
  API_BASES.find(
    ({ Key }) =>
      Key ===
      (window.location.hostname === "marketshare.ascentialedge.com"
        ? "php-prod"
        : window.location.hostname === "marketshare-dev.ms.ascentialedge.com"
        ? "php-dev"
        : window.location.hostname === "marketshare-stage.ms.ascentialedge.com"
        ? "php-stage"
        : window.location.hostname === "marketshare-qa.ms.ascentialedge.com"
        ? "node-qa"
        : window.location.hostname === "localhost"
        ? "node-stage"
        : "php-prod")
  )!.Url;

export const API_BASE_DEFAULT = API_BASES.find(({ Url }) => Url === API_BASE_DEFAULT_URL);

export const RETAILERS_TAG_STORE = {
  [RETAILER_IDS.AMAZON]: "dp",
  [RETAILER_IDS.WALMART]: "ip"
};
